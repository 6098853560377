import { Vue, Component } from "vue-property-decorator";
import { IAdminCompanyInfo, ISelectQuery, ITableFilter, ITableSort } from "@lib";
import AltTable from "@/core/components/alt-table";
import { getDefaultTableColumns } from "../companies/companies-default";
import CompaniesToolbar from "./companies-toolbar/companies-toolbar.vue";
import CompanyViewForm from "./company-view-form/company-view-form.vue";
import { CompaniesFilterController, ICompaniesFilterContext } from "./companies-filter-controller";
import { Formatter } from "@/utils/formatter";

@Component({
    name: "companies",
    components: { AltTable, CompaniesToolbar, CompanyViewForm },
})
export default class Companies extends Vue {
    private AdminCompanyUseCase = this.$alt.system.usecase.createAdminCompanyUseCase();

    private companies: IAdminCompanyInfo[] = [];
    private companiesTotal: number = 0;

    private table: any = {
        settings: {
            limit: undefined,
            columns: [],
            sort: [],
            filter: [],
        },
        page: 1,
        search: "",

        forceUpdate: false,
        update(): void {
            this.forceUpdate = true;
        },
    };

    public filterController: CompaniesFilterController;

    constructor() {
        super();

        this.filterController = new CompaniesFilterController();
        this.filterController.onSave = this.saveFilter.bind(this);
    }

    private formShow = false;
    private form = {
        visible: false,
        company: null as any,

        show(company: any): void {
            this.company = company;
            this.visible = true;
        },
    };

    protected get columns(): any[] {
        return getDefaultTableColumns(this.form);
    }

    protected get limit(): number {
        return this.table.settings.limit || 10;
    }

    protected get skip(): number {
        return (this.table.page - 1) * this.limit;
    }

    protected get sort(): ITableSort[] {
        return this.table.settings.sort || [];
    }

    protected get filter(): ITableFilter[] {
        return this.table.settings.filter || [];
    }

    public async mounted(): Promise<void> {
        try {
            this.$alt.loader.show();
            await this.init();
        } catch (e: any) {
            this.$alt.toast.error(e.message);
        } finally {
            this.$alt.loader.hide();
        }
    }

    private async init(): Promise<void> {
        await this.selectData(this.skip, this.limit, this.sort, this.filter);
    }

    private async exportCompanies(): Promise<void> {
        try {
            const file = await this.AdminCompanyUseCase.export();

            const link = document.createElement("a");
            link.href = URL.createObjectURL(file);
            link.download = `export_${Formatter.now({ format: "YYYYMMDD_HHmmss" })}.csv`;
            link.click();
            setTimeout(() => URL.revokeObjectURL(link.href), 0);

            this.$alt.toast.success("Файл отправлен на скачивание.");
        } catch (e: any) {
            this.$alt.toast.success("Не удалось скачать файл.");
        }
    }

    private async refreshData(): Promise<void> {
        try {
            this.$alt.loader.show();
            await this.init();
            this.$alt.toast.success("Данные обновлены.");
        } catch (e: any) {
            this.$alt.toast.error(e.message);
        } finally {
            this.$alt.loader.hide();
        }
    }

    private async selectData(
        offset: number,
        limit: number,
        tsort: ITableSort[],
        tfilter: ITableFilter[],
    ): Promise<void> {
        try {
            const filter = this.$alt.system.query.convertTableFilter(tfilter);
            const sort = this.$alt.system.query.convertTableSort(tsort);
            let result;
            if (this.table.search.length > 0) {
                const search = this.table.search;
                const options: ISelectQuery = { limit, offset, sort, ...filter, search };
                result = await this.AdminCompanyUseCase.select(options);
            } else {
                const options: ISelectQuery = { limit, offset, sort, ...filter };
                result = await this.AdminCompanyUseCase.select(options);
            }
            this.companies = result.data;
            this.companiesTotal = result.total;
        } catch (e: any) {
            throw new Error(`Не удалось загрузить компании:\n${e.message}`);
        }
    }

    private async searchData(search: string): Promise<void> {
        try {
            // начинать поиск от 2 символов
            this.table.search = search.trim().length > 1 ? search : "";
            await this.selectData(this.skip, this.limit, this.sort, this.filter);
        } catch (e: any) {
            this.companies = [];
            this.companiesTotal = 0;
        }
    }

    private async changeLimit(limit: number, page: number): Promise<void> {
        try {
            // this.table.settings = await this.EmployeeSettings.tableUpdate(
            //     this.companyId,
            //     this.userId,
            //     this.tableType,
            //     { limit: limit }
            // );

            this.table.settings.limit = limit;
            this.table.page = page;
            await this.selectData(0, limit, this.sort, this.filter);
        } catch {}
    }

    private async sortData(sort: any[]): Promise<void> {
        try {
            // const dto: ISettingsTableUpdateDto = { sort };
            // const table = await this.SettingsTable.update(this.companyId, this.userId, this.tableType, dto);
            // if (!table)
            //     throw new Error();

            // this.table.settings = table;
            this.table.settings.sort = sort;

            await this.selectData(this.skip, this.limit, this.sort, this.filter);
        } catch {}
    }

    private async changeTableColumns(columns: any[]): Promise<void> {
        // try {
        //     this.table.settings = await this.EmployeeSettings.tableUpdate(
        //         this.companyId,
        //         this.userId,
        //         this.tableType,
        //         { columns: columns }
        //     );
        // } catch {}
    }

    private async changePage(page: number): Promise<void> {
        try {
            this.$alt.loader.show();

            this.table.page = page;
            await this.selectData(this.skip, this.limit, this.sort, this.filter);
        } finally {
            this.$alt.loader.hide();
        }
    }

    public showFilter(): void {
        const context: ICompaniesFilterContext = {
            currentFilter: this.filter,
        };

        this.filterController.show(context);
    }

    private async saveFilter(filter: ITableFilter[]): Promise<boolean> {
        try {
            this.changeFilter(filter);
            return true;
        } catch (e: any) {
            this.$alt.toast.error(`Не удалось сохранить настройки фильтра:\n${e.message}`);
            return false;
        }
    }

    private async changeFilter(filter: ITableFilter[], withDataUpdate = true): Promise<void> {
        try {
            this.table.settings.filter = filter;

            if (withDataUpdate) {
                await this.selectData(this.skip, this.limit, this.sort, this.filter);
            }
        } catch (e: any) {
            this.$alt.toast.error(e.message);
        }
    }
}
